<template>
    <div>

        <el-dialog :title="title" :visible.sync="dialogVisible" width="400px" :before-close="handleClose">
            <div class="content" v-if="(this.mark !== 'newAddUser')">
                <div class="text" v-if="(mark === 'add')">分享注册链接</div>
                <div class="text" v-if="(mark === 'edit')">设置角色</div>
                <div class="link" v-if="(mark === 'add')">{{ link }}</div>
                <el-select v-model="value" placeholder="请选择" v-if="(mark === 'edit')">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <div class="text" v-if="(mark === 'edit')">用户状态</div>
                <el-switch v-model="switchValue" active-color="#13ce66" inactive-color="#4f4f4f" v-if="(mark === 'edit')">
                </el-switch>
            </div>
            <div class="content" v-if="(this.mark === 'newAddUser')">
                <div class="text">角色名称</div>
                <el-input class="inp" v-model="name" style="margin-bottom:10px;" autofocus="true" />
                <div class="text">角色描述</div>
                <el-input class="inp" v-model="describe" @keyup.enter.native="handleConfirm"/>
            </div>
            <span slot="footer" class="footer">
                <el-button @click="handleClose" style="color:#B9B9B9;background:#212224" class="cancel">取 消</el-button>
                <el-button type="primary" class="copyText" @click="handleConfirm">{{ confirm }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            options: [{
                value: '管理员',
                label: '管理员'
            }, {
                value: '超级管理员',
                label: '超级管理员'
            }],
            value: '',
            switchValue: true,
            name: '',
            describe: ''
        }
    },
    computed: {
        title() {
            let res = ''
            this.mark === 'add' && (res = '添加用户')
            this.mark === 'edit' && (res = '编辑用户')
            this.mark === 'newAddUser' && (res = '新建角色')
            return res
        },
        confirm() {
            let res = ''
            this.mark === 'add' && (res = '复制')
            this.mark === 'edit' && (res = '确认') || this.mark === 'newAddUser' && (res = '确认')
            return res
        }
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        link: {
            type: String,
            default: ''
        },
        mark: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },
        handleConfirm() {
            if (this.mark === 'add') {
                let copyText = this.link
                let el = document.createElement('textarea')
                el.value = copyText
                el.setAttribute('readonly', '')
                el.style = {
                    position: 'absolute',
                    left: '-9999px'
                }
                document.body.appendChild(el)
                el.select()
                document.execCommand('copy')
                document.body.removeChild(el)
                this.$message.success('复制成功！')
                this.$emit('copy')
            } else if (this.mark === 'edit') {
                this.$emit('confirm', { switchValue: this.switchValue, selectValue: this.value })
            } else if (this.mark === 'newAddUser') {
                this.$emit('newAddUser', { name: this.name, describe: this.describe })
                this.name = this.describe = ''
            }
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
    height: 32px;
}

::v-deep .el-switch__core:after {
    background-color: #fff;
}

::v-deep .el-select {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 13px;
}

.content {
    text-align: left;
    padding-left: 15px;
    padding-right: 25px;

    .text {
        color: #E8E8E8
    }

    .link {
        width: 100%;
        height: 28px;
        border: 1px solid #4F4F4F;
        border-radius: 6px;
        padding-left: 8px;
        color: #E8E8E8;
    }
}

.resultDialog {
    img {
        width: 70px;
        height: 70px;
    }

    .tip {
        padding: 3px 7px 3px 7px;

        div {
            line-height: 40px;
            color: #B9B9B9;
        }

        .title {
            font-size: 16px;
            text-align: left;
            color: #E8E8E8;
        }
    }
}

::v-deep .el-button {
    color: #fff;
    width: 80px !important;
    height: 40px !important;
}

.footer {
    text-align: right;
}

::v-deep .el-dialog__header {
    text-align: left;
    padding-top: 25px !important;
    padding-left: 35px !important;
    padding-bottom: 0px !important;

    .el-dialog__title {
        font-size: 20px;
        color: #E8E8E8;
    }
}

::v-deep .el-dialog__footer {
    padding: 5px 40px 30px 0
}

.cancel {
    border: 1px solid rgba(255, 255, 255, 0.2);
}
</style>