<template>
  <div>
    <div class="content">
      <div class="contentTop contentTopOne">
        <div class="contentTopTitle">用户列表</div>
        <div class="contentTopRight">
          <el-tooltip class="item" effect="dark" content="新增" placement="top">
          <img src="@/assets/images/setting/add.png" alt="" @click="addHandler">
        </el-tooltip>
          <el-input placeholder="" suffix-icon="el-icon-search" size="small" v-model="input2" @change="searchHandler">
          </el-input>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tempData" tooltip-effect="dark" style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="showId" label="序号" fixed="left" width="80">
        </el-table-column>
        <el-table-column prop="versionCode" label="账号">
        </el-table-column>
        <el-table-column prop="plannedQuantity" label="姓名">
        </el-table-column>
        <el-table-column prop="orderCompletionDate" label="角色">
        </el-table-column>
        <el-table-column prop="orderStatus" label="状态">
          <template slot="header" slot-scope={}>
            <el-dropdown trigger="click" size="medium" class="OrderStatus" @command="handleCommand">
              <span style="color:#909399">
                状态<i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in section" :key="index" :command="item">
                  {{ item }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template slot-scope="scope">
            <span
              :class="{ 'cell_Green': (scope.row.orderStatus === 1), 'cell_Red': (scope.row.orderStatus === 2) }"></span>
            <span style="margin-left: 10px" v-if="(scope.row.orderStatus === 1)">启用</span>
            <span style="margin-left: 10px" v-if="(scope.row.orderStatus === 2)">禁用</span>
          </template>
        </el-table-column>
        <el-table-column prop="planStartTime" label="创建者">
        </el-table-column>
        <el-table-column prop="planCompletionTime" label="创建时间">
        </el-table-column>
        <el-table-column prop="varianceDay" label="操作">
          <template slot-scope="scope">
            <img src="@/assets/images/plan/analysis/edit.png" alt="" @click="editFn(scope.row)">
            <img src="@/assets/images/plan/analysis/delete.png" alt="" @click="deleteFn(scope.row.id)">
          </template>
        </el-table-column>
      </el-table>
      <div class="totalFlex">
        <span>共{{ total }}条</span>
        <span>
          <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
            :current-page="currentPage1" :page-sizes="[10, 20, 30, 40]" :page-size=pageChange
            layout="prev, pager, next,sizes, jumper" :total=total>
          </el-pagination> </span>
      </div>
    </div>
    <popup :dialogVisible="dialogVisible" @copy="copy" @close="close" :mark="mark" @confirm="confirm" :link="link" />
    <ConfirmDel :dialogVisible="visible" @close="closeHandler" @ok="ok" />
  </div>
</template>

<script>
import popup from '../components/popup'
import ConfirmDel from '@/components/ConfirmDel.vue'
// import { getProductionData, searchProductionData } from '@/api/orders.js'
export default {
  name: "production",
  components: {
    popup,
    ConfirmDel
  },
  data() {
    return {
      name: '',
      fileName: '',
      resultVisible: false,
      resultMsg: '',
      type: true,
      status: false,
      tableData: [],
      currentPage1: 1,
      total: 0,  //总条数，用于分组组件
      input2: '',
      section: [
        '启用',
        '禁用'
      ],
      tempData: [],
      tempData2: [],
      pageChange: 10,   //当前页面多少条订单
      tempTotal: 0,  //总条数，但与分页组件无关
      searchStatus: false,
      searchData: [],
      dialogVisible: false,
      visible: false,
      mark: '',
      link: 'http://cycv.uej.pgbx'
    }
  },
  methods: {
    editFn() {
      this.dialogVisible = true
      this.mark = 'edit'
    },
    deleteFn() {
      this.visible = true
    },
    addHandler() {
      this.dialogVisible = true
      this.mark = 'add'
    },
    copy() {
      this.dialogVisible = false
    },
    confirm(val) {
      this.dialogVisible = false
      // 用户状态
      console.log('当前打印:', val)
    },
    close() {
      this.dialogVisible = false
    },
    closeHandler() {
      this.visible = false
    },
    ok() {
      this.visible = false
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 组件一页显示多少条数据
    // async handleSizeChange(val) {
    //   this.pageChange = val
    //   if (this.status) {
    //     // 如果页面数据经过筛选，分页组件应拿筛选后的数据this.tempData2
    //     this.tempData = this.tempData2.filter((v, i) => i < val)
    //   } else if (this.searchStatus) {
    //     this.tempData = this.searchData.filter((v, i) => i < val)
    //   } else {
    //     const res = await getProductionData(1, val)
    //     if (res.code === 0) {
    //       const { records } = res.data
    //       this.tableData = records
    //       this.tempData = this.tableData
    //     }
    //   }
    //   this.currentPage1 = 1
    // },
    // 选择页数
    // async handleCurrentChange(val) {
    //   if (this.status) {
    //     // 如果数据经过筛选
    //     this.tempData = this.tempData2.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
    //   } else if (this.searchStatus) {
    //     this.tempData = this.searchData.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
    //   } else {
    //     const res = await getProductionData(val, this.pageChange)
    //     if (res.code === 0) {
    //       const { records } = res.data
    //       this.tableData = records
    //       this.tempData = records
    //     }
    //   }
    //   this.currentPage1 = val
    // },
    // async searchHandler() {
    //   const res = await searchProductionData(1, this.tempTotal, this.input2)
    //   // 搜索后的总条数
    //   this.searchData = res.data.records
    //   this.searchStatus = true
    //   this.pageChange = 10
    //   this.currentPage1 = 1
    //   this.tempData = this.searchData.filter((v, i) => i < 10)
    //   this.total = this.searchData.length
    // },
    // 筛选状态
    // async handleCommand(item) {
    //   const res = await getProductionData(1, this.tempTotal)
    //   const { records } = res.data
    //   this.status = true
    //   this.currentPage1 = 1
    //   this.pageChange = 10
    //   if (item === '启用') {
    //     this.tempData2 = records.filter(v => v.orderStatus === 1)
    //     this.tempData = this.tempData2.filter((v, i) => i < 10)
    //   } else if (item === '禁用') {
    //     this.tempData2 = records.filter(v => v.orderStatus === 2)
    //     this.tempData = this.tempData2.filter((v, i) => i < 10)
    //   }
    //   this.total = this.tempData2.length
    // },
    // async renovate() {
    //   // 刷新，重新赋值数据
    //   const res = await getProductionData(1, 10)
    //   const { records, total } = res.data
    //   this.tableData = this.tempData = records
    //   this.total = this.tempTotal = total
    //   this.pageChange = 10
    //   this.currentPage1 = 1
    //   this.status = this.searchStatus = false
    //   this.input2 = ''
    // },
    closeFn() {
      this.resultVisible = false
    },
  },
  async created() {
    await this.renovate()
  }
}
</script>

<style lang="sass" scoped>
@import './style/style.scss'
</style>
